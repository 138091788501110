import { Container, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import CustumTable from '../components/CustumTable';
import SoftwareModal from '../components/SoftwareModal';

export default function SoftWares() {
    const [open, setOpen] = React.useState(false);
    const dropDownData = [
        { label: 'Amit' },
        { label: 'Rohit Singh' },
        { label: 'Ashi' },
        { label: 'Nishant Singh' }
    ];
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const column = [
        // { id: 'photo', label: "ID", renderCell: () => {
        //         return(<>
        //             <IconButton>
        //                 <Avatar sx={{ width: 35, height: 35, marginLeft: "20px"}} />
        //             </IconButton>
        //         </>)
        //     } },
        { id: "name", label: "Name" },
        { id: "color-bg", label: "Background Color" },
        { id: "color-text", label: "Text Color" },
        { id: "color-border", label: "Border Color" },
        { id: "createdat", label: "Created at" },

    ]
    const row = [
        {id: 1, name: "Amit", mobile: '12234355353', lastLogin : "time", status: "Active", createdat: "Created at"}
    ]
    return (
        <>
            <Grid>
                <Container maxWidth>
                    <Stack sx={{marginTop: "30px"}} direction="row" alignItems="center" justifyContent="space-between">
                        <Typography sx={{fontSize: "25px", color: "#04174d", fontWeight: "bold"}}>
                            Softwares
                        </Typography>
                        <SoftwareModal dropDownData={dropDownData} open={open} handleClickOpen={handleClickOpen} handleClose={handleClose}/>
                    </Stack>
                    <CustumTable column={column} row={row}/>
                </Container>
            </Grid>
        </>
    )
}
