import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Grid, Typography, Stack, TextField, Autocomplete } from '@mui/material';
import Dropdown from './Dropdown';
export default function SoftwareModal({dropDownData, open, handleClickOpen, handleClose}) {

    return (
        <React.Fragment>
            <Button onClick={handleClickOpen} variant='contained' sx={{ backgroundColor: "#04174d", color: "#fff", fontSize: "13px", padding: "10px 16px 10px 16px", boxShadow: "1px 3px 1px #9E9E9E" }}>Create</Button>

            <Dialog
                sx={{ width: "100%" }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent dividers={true}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid>

                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography sx={{ fontSize: "25px", color: "#04174d", fontWeight: "bold" }}>
                                        Add Company
                                    </Typography>
                                </Stack>
                                <Grid mt={3} mb={4} container spacing={2}>
                                    <Grid item xs={6} md={4}>
                                        <TextField
                                            sx={{ width: "100%" }}
                                            label="Name"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={dropDownData}
                                            sx={{ width: "100%", backgroundColor: "#ededed" }}
                                            renderInput={(params) => <TextField {...params} label="Select Client" />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Dropdown />

                                    </Grid>
                                    <Grid item xs={8} md={12}>
                                        <TextField
                                            sx={{ width: "100%" }}
                                            label="Address"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Button sx={{ width: "100%", fontSize: "15px", fontWeight: "bold", backgroundColor: "#04174d" }} variant='contained'>Submit</Button>
                        </Grid>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </React.Fragment>
    );
}
