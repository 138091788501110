import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import PublicRoute from '../helpers/PublicRoutes'
import Login from '../pages/Login'
import ProtectedRoutes from '../helpers/ProtectedRoutes'
import { Url } from './Url'
import Header from '../components/Header'
import Otp from '../pages/Otp'

export function Routers() {
    return (
        <>
            <Routes>
                <Route path='/' element={<PublicRoute />}>
                    <Route path='/' element={<Navigate replace to="./login" />} />
                    <Route path='/login' element={<Login/>} />
                    <Route path='/otp' element={<Otp/>}/>
                    <Route />
                </Route>
                <Route path='/' element={<ProtectedRoutes/>}>
                    <Route element={<Header/>}>
                        {Url.map(({path, element}) => {
                            return <Route path={path} element={element} key={path}/>
                        })}
                    </Route>
                </Route>
            </Routes>
        </>
    )
}
