import React, {useState} from 'react'
import LoginAnimation from '../assets/animation/login.json'
import {Grid, Box, Typography, Avatar, TextField, Button,} from '@mui/material';
import Lottie from 'lottie-react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useNavigate} from 'react-router-dom';
import ApiUrl from "../Global";

const Otp = () => {
    const [userOtp, setUserOtp] = useState('');
    const navigate = useNavigate()
    const userOtpApi = async (e) => {
        e.preventDefault()
        try {
            const formdata = new FormData();
            formdata.append('otp', userOtp);
            formdata.append('mobile', "8171675127");
            let res;
            res = await fetch(ApiUrl + 'auth/verify-login-otp', {
                method: "POST",
                body: formdata,
                redirect: 'follow'
            });
            const data = await res.json();
            console.log(data, "-----------data");
            if (data.type === 'success') {
                const loginToken = data.data.token
                localStorage.setItem('token', loginToken);
                const getToken = localStorage.getItem('token');
                console.log(getToken)
                navigate('/clients')
            }
        } catch (err) {
            console.log(err.message, "==========api error===========")
        }
    }
    return (
        <>
            <Grid container spacing={3} width="100%" sx={{height: "103vh"}}>
                <Grid item xs={7} bgcolor="#5192eb" sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "linear-gradient(81deg, rgba(0,79,249,1) 0%, rgba(0,0,0,1) 95%, rgba(17,74,226,1) 100%)"
                }}>
                    <Box p={6.5} width={595}>
                        <Lottie sx={{boxShadow: "1px 3px 1px #9E9E9E"}} animationData={LoginAnimation}/>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Grid align="center" sx={{marginTop: "100px"}}>
                        <Avatar sx={{
                            boxShadow: "1px 3px 1px #9E9E9E",
                            background: "linear-gradient(81deg, rgba(0,79,249,1) 0%, rgba(0,0,0,1) 95%, rgba(17,74,226,1) 100%)",
                            padding: "10px"
                        }}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography color="#114ae2" align="center" fontSize={"32px"} fontWeight={500}
                                    mt={3}>OTP</Typography>
                    </Grid>
                    <form>
                        <Grid px={10} mt={6}>
                            <TextField
                                id="otp"
                                label="Enter Your OTP"
                                type="text"
                                name="otp"
                                value={userOtp}
                                onChange={(e) => setUserOtp(e.target.value)}
                                sx={{width: "100%"}}
                            />
                        </Grid>
                        <Grid align="center" mt={5} px={10}>
                            <Button onClick={userOtpApi}
                                    variant="contained"
                                    sx={{
                                        boxShadow: "1px 3px 1px #9E9E9E",
                                        width: "100%",
                                        padding: "13px",
                                        fontSize: "17px",
                                        background: "linear-gradient(81deg, rgba(0,79,249,1) 0%, rgba(0,0,0,1) 95%, rgba(17,74,226,1) 100%)",
                                        fontWeight: "bold"
                                    }}
                                    type='submit'
                            >
                                Submit
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </>
    )
}

export default Otp
