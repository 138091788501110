import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {Grid, Typography, Stack, TextField, Autocomplete} from '@mui/material';
import Dropdown from './Dropdown';
import ApiUrl from "../Global";
import {useEffect, useState} from "react";

export default function ClientModal({ open, handleClickOpen, handleClose, clientData, setClientData, clientDataApi}) {
    const [rolesData, setRolesData] = useState([]);
    // const [value, setValue] = useState("");
    const Status = [
        { label: "Active" },
        { label: "Inactive" }
    ]
    // const [clientData, setClientData] = useState({
    //     name:'',
    //     email:'',
    //     mobile:'',
    //     whatsApp:'',
    //     role_id:'',
    //     status:'',
    //     address:'',
    //     pin:'',
    //     city:'',
    //     state:'',
    // });



    const handleData = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value,
        });
    };
    // console.log(clientData,'---')
    // const clientDataApi = async () => {
    //     let login_token = await  localStorage.getItem('token');
    //     console.log(login_token)
    //     try {
    //         let myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");
    //         myHeaders.append("Authorization", `Bearer ${login_token}`);
    //         const formdata = new FormData();
    //         formdata.append("name", clientData.name);
    //         formdata.append("mobile", clientData.mobile);
    //         formdata.append("status", clientData.status);
    //         formdata.append("address", clientData.address);
    //         formdata.append("pin", clientData.pin);
    //         formdata.append("city", clientData.city);
    //         formdata.append("state", clientData.status);
    //         formdata.append("email", clientData.email);
    //         formdata.append("role_id", clientData.role_id);
    //         formdata.append("whatsApp", clientData.whatsApp);
    //         let res;
    //         res = await fetch(ApiUrl + 'create/user', {
    //             method: "POST",
    //             body: formdata,
    //             headers: myHeaders,
    //             redirect: 'follow'
    //         });
    //         const data = await res.json();
    //         console.log(data, "Data=====================")
    //     } catch (err) {
    //         console.log(err.message, "==========api error===========")
    //     }
    // }
    console.log(clientData, "clientDataTable")
    const roleApi = async () => {
            try {
                let res;
                res = await fetch(ApiUrl + 'retrieve/roles', {
                    method: "GET",
                    redirect: 'follow'
                });
                const {data:{roles}} = await res.json();
                setRolesData(roles);
                console.log(rolesData)
            } catch (err) {
                console.log(err)
            }
        }

    useEffect(() => {
        roleApi()
    }, [])

    return (
        <React.Fragment>
            <Button onClick={handleClickOpen} variant='contained' sx={{ backgroundColor: "#04174d", color: "#fff", fontSize: "13px", padding: "10px 16px 10px 16px", boxShadow: "1px 3px 1px #9E9E9E" }}>Create</Button>
            <Dialog
                sx={{ width: "100%" }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent dividers={true}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid>
                            <Stack>
                                <Typography sx={{ fontSize: "25px", color: "#04174d", fontWeight: "bold" }}>
                                    Add Client
                                </Typography>
                            </Stack>
                            <Grid mt={3} mb={4} container spacing={2}>
                                <Grid item xs={6} md={4}>
                                    <TextField
                                        sx={{ width: "100%" }}
                                        name='name'
                                        label="Name"
                                        variant="outlined"
                                        value={clientData.name}
                                        onChange={(e) => handleData(e)}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <TextField
                                        sx={{ width: "100%" }}
                                        label="Mobile"
                                        name='mobile'
                                        variant="outlined"
                                        value={clientData.mobile}
                                        onChange={(e) => handleData(e)}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={Status}
                                        value={clientData.status}
                                        onChange={(event, newValue) => {
                                            setClientData({
                                                ...clientData,
                                                status : newValue.label
                                            });

                                        }}
                                        sx={{ width: "100%", backgroundColor: "#ededed"}}
                                        renderInput={(params) => <TextField {...params} label="Status" />}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={rolesData}
                                        getOptionLabel={(option) => option.name}
                                        value={rolesData.id}
                                        onChange={(event, newValue) => {
                                            setClientData({
                                                ...clientData,
                                                role_id : newValue.id
                                            });
                                        }}
                                        sx={{ width: "100%", backgroundColor: "#ededed" }}
                                        renderInput={(params) => <TextField {...params} label="Role" />}
                                    />
                                </Grid>
                                <Grid item xs={8} md={4}>
                                    <TextField
                                        sx={{ width: "100%" }}
                                        name='whatsApp'
                                        label="WhatsApp"
                                        variant="outlined"
                                        value={clientData.whatsApp}
                                        onChange={(e) => handleData(e)}
                                    />
                                </Grid>
                                <Grid item xs={8} md={4}>
                                    <TextField
                                        sx={{ width: "100%" }}
                                        label="Email"
                                        name='email'
                                        variant="outlined"
                                        value={clientData.email}
                                        onChange={(e) => handleData(e)}
                                    />
                                </Grid>
                                <Grid item xs={8} md={12}>
                                    <TextField
                                        sx={{ width: "100%" }}
                                        label="Address"
                                        name='address'
                                        variant="outlined"
                                        value={clientData.address}
                                        onChange={(e) => handleData(e)}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <TextField
                                        sx={{ width: "100%" }}
                                        label="Pin Code"
                                        name='pin'
                                        variant="outlined"
                                        value={clientData.pin}
                                        onChange={(e) => handleData(e)}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <TextField
                                        sx={{ width: "100%" }}
                                        label="City"
                                        name='city'
                                        variant="outlined"
                                        value={clientData.city}
                                        onChange={(e) => handleData(e)}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <TextField
                                        sx={{ width: "100%" }}
                                        label="State"
                                        name='state'
                                        variant="outlined"
                                        value={clientData.state}
                                        onChange={(e) => handleData(e)}
                                    />
                                </Grid>
                            </Grid>
                            <Button onClick={() => {
                                clientDataApi()
                                    handleClose()
                            }}  sx={{ width: "100%", fontSize: "15px", fontWeight: "bold", backgroundColor: "#04174d" }} variant='contained'>Submit</Button>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
