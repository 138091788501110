import React from 'react'
import './App.css';
import Header from './components/Header';
import Clients from './pages/Clients';
import Companies from './pages/Companies';
import { Route, Routes } from 'react-router-dom';
import AddClient from './pages/AddClient';
import AddCompany from './pages/AddCompany';
import Messages from './pages/Messages';
import SoftWares from './pages/SoftWares';
import AddSoftware from './pages/AddSoftware';
import Login from './pages/Login';
import Otp from './pages/Otp';
import { Routers } from './routes/root';

export default  function App() {
  return (
    <>
    <Routers/>
    {/* <Login/> */}
    {/* <Otp/> */}
    {/* <Routes>
      <Route path='/' element={<Header/>}>
        <Route path='/clients' element={<Clients/>}/>
        <Route path='/companies' element={<Companies/>}/>
        <Route path='/softWares' element={<SoftWares/>}/>
        <Route path='/addClient' element={<AddClient/>}/>
        <Route path='/addcompany' element={<AddCompany/>}/>
        <Route path='/addsoftware' element={<AddSoftware/>}/>
        <Route path='/message' element={<Messages/>}/>
      </Route>
     </Routes> */}
    </>
  )
}
