import { AppBar, Box, Button, Stack, Toolbar, Typography } from '@mui/material'
import React, {useState} from 'react'
import {Link, Outlet, useNavigate} from 'react-router-dom'
import ApiUrl from "../Global";

export default function Header() {
    let logoutApiResult
    const navigate = useNavigate();
    const Logout = async () => {
            let login_token = await  localStorage.getItem('token');
            console.log(login_token)
            try{
                let myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${login_token}`);
                const logoutApi = await fetch(ApiUrl + 'auth/logout', {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: "follow"
                });
                logoutApiResult = await logoutApi.json();
                console.log(logoutApiResult);
                if (logoutApiResult.type === 'success') {
                    await localStorage.removeItem('token');
                    navigate('/login');
                }
            }catch(err){
                console.log(err, "api err")
            }
        }
    return (
        <>
            <Box>
                <AppBar position='static' sx={{ backgroundColor: "#04174d", padding: "10px 0px 10px 0px", boxShadow: "0px 3px 0px #9E9E9E" }}>
                    <Toolbar>
                        <Typography variant='h5' sx={{ color: "#fff" }}>OTP Manager</Typography>
                        <Box sx={{ marginLeft: "40px" }}>
                            <Stack direction="row" spacing={5} alignItems={"center"}>
                                <Link to="/clients" style={{ textDecoration: "none" }}>
                                    <Button variant='text' sx={{ color: "#fff", boxShadow: "1px 1px 1px #9E9E9E" }}>Clients</Button>
                                </Link>
                                <Link to="/companies" style={{ textDecoration: "none" }}>
                                    <Button variant='text' sx={{ color: "#fff", boxShadow: "1px 1px 1px #9E9E9E" }}>Companies</Button>
                                </Link>
                                <Link to="/softWares" style={{ textDecoration: "none" }}>
                                    <Button variant='text' sx={{ color: "#fff", boxShadow: "1px 1px 1px #9E9E9E" }}>Softwares</Button>
                                </Link>
                                <Button onClick={Logout} variant='text' sx={{ color: "#fff", boxShadow: "1px 1px 1px #9E9E9E" }}>LOGOUT</Button>
                            </Stack>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <Outlet />
        </>
    )
}
