import React from 'react'
import Clients from '../pages/Clients'
import Companies from '../pages/Companies'
import SoftWares from '../pages/SoftWares'
import AddClient from '../pages/AddClient'
import AddCompany from '../pages/AddCompany'
import Messages from "../pages/Messages";

export const Url = [
    {path: "/clients", element: <Clients/>},
    {path: "/companies", element: <Companies/>},
    {path: "/softWares", element: <SoftWares/>},
    {path: "/message", element: <Messages/>}

]
