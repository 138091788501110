import {
    Avatar,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material'
import React from 'react'

export default function CustumTable({ column, row, clientData }) {
    const [page, setPage] = React.useState(0);;
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
        console.log(clientData, "===========")
    // console.log(clientData.name, "clientData")
    return (
        <Paper sx={{ overflow: "hidden", marginTop: "2rem" }}>
            <TableContainer sx={{ maxHeight: 500 }}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {column.map((column) => {
                                return (
                                    <TableCell sx={{ backgroundColor: "#ededed", fontSize: "15px", fontWeight: "bold", color: "#04174d" }} align='center' key={column.id}>
                                        {column.label}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    {/*<TableBody>*/}
                    {/*    {row && row.map((row, index) => {*/}
                    {/*        return (*/}
                    {/*            <TableRow key={index}>*/}
                    {/*                {column.map((column) => {*/}
                    {/*                    const value = row[column?.id];*/}
                    {/*                    return <TableCell sx={{ fontSize: "13px" }} align='center' key={column.id}>*/}
                    {/*                        {column.renderCell ? column.renderCell(row) : value ? value : 'N/A'}*/}
                    {/*                    </TableCell>;*/}
                    {/*                })}*/}
                    {/*            </TableRow>*/}
                    {/*        )*/}
                    {/*    })}*/}
                    {/*</TableBody>*/}
                    <TableBody>

                        {clientData?.map((item, index) => {
                            console.log(item, "]]]]]]")
                            return(<>
                            <TableRow key={index}>
                                <TableCell sx={{ fontSize: "13px" }} align='center' key={column.id}>
                                    <IconButton>
                                        <Avatar sx={{ width: 35, height: 35,}} />
                                    </IconButton>
                                </TableCell>
                                <TableCell sx={{ fontSize: "13px" }} align='center' key={column.id}>{item.name}</TableCell>
                                <TableCell sx={{ fontSize: "13px" }} align='center' key={column.id}>{item.email}</TableCell>
                                <TableCell sx={{ fontSize: "13px" }} align='center' key={column.id}>{item.mobile}</TableCell>
                                <TableCell sx={{ fontSize: "13px" }} align='center' key={column.id}>{item.whatsApp}</TableCell>
                                <TableCell sx={{ fontSize: "13px" }} align='center' key={column.id}>null</TableCell>
                                <TableCell sx={{ fontSize: "13px" }} align='center' key={column.id}>{item.role}</TableCell>
                                <TableCell sx={{ fontSize: "13px" }} align='center' key={column.id}>{item.status}</TableCell>
                                {/*<TableCell sx={{ fontSize: "13px" }} align='center' key={column.id}>{item.createdAt}</TableCell>*/}
                                {/*<TableCell sx={{ fontSize: "13px" }} align='center' key={column.id}>{item.created_by_name}</TableCell>*/}
                            </TableRow>
                            </>)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={row?.length || 0} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </Paper>
    )
}
