import { Card, Container, Grid, Typography } from '@mui/material'
import React from 'react'

export default function Messages() {
  return (
    <>
      <Grid sx={{ padding: "20px" }}>
        <Container maxWidth>
          <Typography variant='h5' sx={{ color: "#04174d", fontWeight: "bold", marginTop: "30px" }}>
            Messages
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={3} md={3}>
              <Card variant='outlined'>
                <Typography sx={{ borderBottom: 1, padding: "10px 5px 5px 10px", borderBottomColor: "#ccc", backgroundColor: "#d9e3ff" }}>
                  Technosters CRM (Abhishek Singh)
                </Typography>
                <Typography sx={{ borderBottom: 1, padding: "10px 5px 5px 10px", borderBottomColor: "#ccc" }}>
                  Institute Panal Login OTP for Abhishek Singh is 111111
                </Typography>
                <Typography sx={{ padding: "5px 10px 5px 10px", backgroundColor: "#d9e3ff" }}>
                  04-May-2024 06.01.42 PM
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={3} md={3}>
              <Card sx={{ padding: "0px 0px 0px 0px" }} variant='outlined'>
                <Typography sx={{ borderBottom: 1, padding: "10px 5px 5px 10px", borderBottomColor: "#ccc", backgroundColor: "#d9e3ff" }}>
                  Technosters CRM (Abhishek Singh)
                </Typography>
                <Typography sx={{ borderBottom: 1, padding: "10px 5px 5px 10px", borderBottomColor: "#ccc" }}>
                  Institute Panal Login OTP for Abhishek Singh is 111111
                </Typography>
                <Typography sx={{ padding: "5px 10px 5px 10px", backgroundColor: "#d9e3ff" }}>
                  04-May-2024 06.01.42 PM
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={3} md={3}>
              <Card sx={{ padding: "0px 0px 0px 0px" }} variant='outlined'>
                <Typography sx={{ borderBottom: 1, padding: "10px 5px 5px 10px", borderBottomColor: "#ccc", backgroundColor: "#d9e3ff" }}>
                  Technosters CRM (Abhishek Singh)
                </Typography>
                <Typography sx={{ borderBottom: 1, padding: "10px 5px 5px 10px", borderBottomColor: "#ccc" }}>
                  Institute Panal Login OTP for Abhishek Singh is 111111
                </Typography>
                <Typography sx={{ padding: "5px 10px 5px 10px", backgroundColor: "#d9e3ff" }}>
                  04-May-2024 06.01.42 PM
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={3} md={3}>
              <Card sx={{ padding: "0px 0px 0px 0px" }} variant='outlined'>
                <Typography sx={{ borderBottom: 1, padding: "10px 5px 5px 10px", borderBottomColor: "#ccc", backgroundColor: "#d9e3ff" }}>
                  Technosters CRM (Abhishek Singh)
                </Typography>
                <Typography sx={{ borderBottom: 1, padding: "10px 5px 5px 10px", borderBottomColor: "#ccc" }}>
                  Institute Panal Login OTP for Abhishek Singh is 111111
                </Typography>
                <Typography sx={{ padding: "5px 10px 5px 10px", backgroundColor: "#d9e3ff" }}>
                  04-May-2024 06.01.42 PM
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  )
}
