import React from 'react'
import { Avatar, Container, Grid, IconButton, Stack, Typography } from '@mui/material'
import MessageIcon from '@mui/icons-material/Message';
import CustumTable from '../components/CustumTable';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import CompanyModal from '../components/CompanyModal';
export default function Companies() {
    const [open, setOpen] = React.useState(false);
    const dropDownData = [
        { label: 'Amit' },
        { label: 'Rohit Singh' },
        { label: 'Ashi' },
        { label: 'Nishant Singh' }
    ];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();
    const column = [
        { id: 'photo', label: "ID", renderCell: () => {
            return(<>
                <IconButton>
                    <Avatar sx={{ width: 35, height: 35}} />
                </IconButton>
            </>)
        } },
        { id: "name", label: "Name" },
        { id: "apikey", label: "API Key" },
        { id: "usein", label: "Use In" },
        { id: "client", label: "Client" },
        { id: "status", label: "Status" },
        { id: "createdby", label: "Created by" },
        { id: "createdat", label: "Created at" },
        {
            id: "action", label: "Action", renderCell: (data) => {
                return (
                    <>
                        <Stack direction="row" spacing={1} justifyContent="center">
                            <IconButton onClick={() => navigate('/message')} variant='contained' sx={{ backgroundColor: "#04174d", boxShadow: "1px 3px 1px #9E9E9E" }}>
                                <MessageIcon sx={{ fontSize: "20px", color: "#fff", textAlign: "center" }} />
                            </IconButton>
                            <IconButton onClick={handleClickOpen} variant='contained' sx={{ backgroundColor: "#04174d", boxShadow: "1px 3px 1px #9E9E9E" }}>
                                <EditIcon sx={{ fontSize: "20px", color: "#fff", textAlign: "center" }} />
                            </IconButton>
                        </Stack>
                    </>
                )
            }
        }
    ]
    const row = [
        { id: 1, name: "Amit", apikey: '12234355353', usein: "12314", client: "Client", status: "Active", createdby: "Created by", createdat: "Created at" }
    ]
    return (
        <>
            <Grid>
                <Container maxWidth>
                    <Stack sx={{ marginTop: "30px" }} direction="row" alignItems="center" justifyContent="space-between">
                        <Typography sx={{ fontSize: "25px", color: "#04174d", fontWeight: "bold" }}>
                            Companies
                        </Typography>
                        <CompanyModal dropDownData={dropDownData} open={open} handleClickOpen={handleClickOpen} handleClose={handleClose}/>
                    </Stack>
                    <CustumTable column={column} row={row} />
                </Container>
            </Grid>
        </>
    )
}
