import { Avatar, Container, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, {useState} from 'react'
import MessageIcon from '@mui/icons-material/Message';
import CustumTable from '../components/CustumTable';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import ClientModal from '../components/ClientModal';
import ApiUrl from "../Global";

export default function Clients() {
    const [open, setOpen] = React.useState(false);
    const [clientData, setClientData] = useState({
        name:'',
        email:'',
        mobile:'',
        whatsApp:'',
        role_id:'',
        status:'',
        address:'',
        pin:'',
        city:'',
        state:'',
    });
    const clientDataApi = async () => {
        let login_token = await  localStorage.getItem('token');

        try {
            let myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${login_token}`);
            console.log(login_token)
            let formData = new FormData();
            formData.append("name", clientData.name);
            formData.append("mobile", clientData.mobile);
            formData.append("status", clientData.status);
            formData.append("address", clientData.address);
            formData.append("pin", clientData.pin);
            formData.append("city", clientData.city);
            formData.append("state", clientData.status);
            formData.append("email", clientData.email);
            formData.append("role_id", clientData.role_id);
            formData.append("whatsApp", clientData.whatsApp);

            let res = await fetch(ApiUrl + 'create/user', {
                method: "POST",
                body: formData,
                headers: myHeaders,
                redirect: 'follow'
            });
            const data = await res.json();
            console.log(data, "Data=====================11111")
        } catch (err) {
            console.log(err.message, "==========api error===========22222")
        }
    }
    // const dropDownData = [
    //     { label: 'Active' },
    //     { label: 'Inactive' },
    // ];
    // const roleData = [
    //     { label: 'Client' },
    //     { label: 'Company' },
    //     { label: 'Software' },
    // ];
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const navigate = useNavigate();
    const column = [
        { id: 'photo', label: "ID", renderCell: () => {
            return(<>
                <IconButton>
                    <Avatar sx={{ width: 35, height: 35,}} />
                </IconButton>

            </>)
        } },
        { id: "name", label: "Name" },
        { id: 'email', label: "Email" },
        { id: "mobile", label: "Mobile" },
        { id: "whatsapp", label: "WhatsApp" },
        { id: "lastLogin", label: "Last Login" },
        { id: "role", label: "Role" },
        { id: "status", label: "Status" },
        { id: "createdat", label: "Created at" },
        { id: "createdby", label: "Created by" },
        {
            id: "action", label: "Action", renderCell: (data) => {
                return (
                    <>
                        <Stack direction="row" spacing={1} justifyContent="center">
                            <IconButton onClick={() => navigate('/message')} disableFocusRipple="true" sx={{ backgroundColor: "#04174d"}}>
                                <MessageIcon sx={{ fontSize: "20px", color: "#fff", textAlign: "center" }} />
                            </IconButton>
                            <IconButton onClick={handleClickOpen} sx={{ backgroundColor: "#04174d" }}>
                                <EditIcon sx={{ fontSize: "20px", color: "#fff", textAlign: "center" }} />
                            </IconButton>
                        </Stack>
                    </>
                )
            }
        }
    ]
    const row = [
        {name: "Amit", mobile: '12234355353', lastLogin: "time", status: "Active", createdat: "Created at" }
    ]
    return (
        <>
            <Grid>
                <Container maxWidth>
                    <Stack sx={{ marginTop: "30px" }} direction="row" alignItems="center" justifyContent="space-between">
                        <Typography sx={{ fontSize: "25px", color: "#04174d", fontWeight: "bold" }}>
                            Clients
                        </Typography>
                        <ClientModal
                            open={open}
                            handleClickOpen={handleClickOpen}
                            handleClose={handleClose}
                            clientData={clientData}
                            setClientData={setClientData}
                            clientDataApi={clientDataApi}
                        />
                    </Stack>
                    <CustumTable column={column} row={row} clientData={clientData} />
                </Container>
            </Grid>
        </>
    )
}
