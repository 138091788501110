import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function Dropdown() {
    const Status = [
        { label: "Active" },
        { label: "Inactive" }
    ]
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={Status}
      sx={{ width: "100%", backgroundColor: "#ededed"}}
      renderInput={(params) => <TextField {...params} label="Status" />}
    />
  );
}

